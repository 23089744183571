<template>
  <b-container class="b-card-forms">
    <b-row class="vh-100" align-h="center">
      <b-col align-self="center" cols="12" sm="9" lg="5">
        <b-card class="text-center error">
          <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
          <h3 class="mb-4">{{ $t('notInAllowedRoles.title') }}</h3>
          <b-alert
            show
            variant="danger"
            v-html="$t('notInAllowedRoles.error')"
          ></b-alert>
          <router-link class="mt-2 mb-5 btn py-2 px-4 btn-success" to="/webmag-logout">
            {{ $t('buttons.logout') }}
          </router-link>
          <div v-if="!onlyOneGroupForUserExists && userIdExists">
            <h3>{{ $t('notInAllowedRoles.changeGroup') }}</h3>
            <header-group-dropdown
              :is-sys-admin="false"
              :user-id="userId"
              :group-id="null"
              @only-one-group-exists="onlyOneGroupForUserExists = true"
            />
          </div>
          <p class="mt-4" v-html="$t('notInAllowedRoles.desc')"></p>
          <div v-if="codeBlock" class="mt-3">
            <hr>
            <div class="d-block text-left mt-2 error-msg-block">
              <h4>Error Message:</h4>
              <div v-if="debugData" class="mb-2" v-html="debugData"></div>
              <div v-html="codeBlock"></div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Auth from '@aws-amplify/auth';
import SetLanguageToBrowserLanguage from '@/mixins/setLanguageToBrowserLanguage';

export default {
  name: 'NotInAllowedRoles',
  mixins: [SetLanguageToBrowserLanguage],
  components: {
    HeaderGroupDropdown: () => import('@/components/header/Buttons/HeaderGroupDropdown.vue'),
  },
  data() {
    return {
      problem: null,
      debugData: null,
      codeBlock: null,
      userId: null,
      onlyOneGroupForUserExists: false,
    };
  },
  computed: {
    userIdExists() {
      return this.userId !== null;
    },
  },
  async created() {
    this.$currentUserRole = 'user';
    try {
      const awsUser = await Auth.currentAuthenticatedUser();
      const userInfo = JSON.parse(awsUser.signInUserSession.getIdToken().payload['https://hasura.io/jwt/claims']);
      if (!userInfo['x-hasura-user-id']) {
        this.setLanguageToBrowserLanguage();
      } else {
        this.userId = parseInt(userInfo['x-hasura-user-id'], 10);
      }
    } catch (error) {
      this.setLanguageToBrowserLanguage();
    }
    if (this.$route.query?.error) {
      this.problem = this.$route.query.error;
    }
    if (this.$route.query?.debugData) {
      this.debugData = this.$route.query.debugData;
    }
    if (this.$route.query?.errorData) {
      this.codeBlock = JSON.parse(this.$route.query.errorData);
    }
  },
};
</script>

<style scoped lang="scss">
h4 {
  font-size: 13px;
  font-weight: bold;
}
.error-msg-block {
  font-size: 12px;
  line-height: 15px;
  color: #000;
}
/deep/ .group-switcher-wrapper {
  justify-content: center;
  background: none !important;
  .vue-treeselect input {
    padding: 0;
    background: none;
  }
}
</style>
